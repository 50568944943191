/*--------------------------------------------------------------
# Use cases carousel
--------------------------------------------------------------*/
#use-cases {
    .use-case-item {
      box-sizing: content-box;
      padding: 20px 20px 0 20px;
      margin-left: 15px;
      margin-right: 15px;
      text-align: center;
      min-height: 350px;
      box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
      .use-case-img {
        width: 90px;
        border-radius: 50%;
        border: 4px solid $light;
        margin: 0 auto;
      }
      h3 {
        font-size: 18px;
        font-weight: bold;
        margin: 10px 0 5px 0;
        color: #111;
      }
      h4 {
        font-size: 14px;
        color: #999;
        margin: 0;
      }
      .quote-icon-left, .quote-icon-right {
        color: $primary-light;
        font-size: 26px;
        width: 100%;
      }
      .quote-icon-left {
        display: inline-block;
        left: -5px;
        position: relative;
        text-align-last: left;
        width: 100%;
      }
      .quote-icon-right {
        display: inline-block;
        right: -5px;
        position: relative;
        text-align: right;
        top: 10px;
      }
      p {
        font-style: italic;
        margin: 0 auto 15px auto;
        .quote {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3; /* number of lines to show */
          -webkit-box-orient: vertical;
        }
      }
    }
    .swiper-slide {
      height: auto;
    }
    .swiper-pagination {
      margin-top: 60px;
      position: relative;
      .swiper-pagination-bullet {
        width: 12px;
        height: 12px;
        background-color: $light;
        opacity: 1;
        border: 1px solid $primary;
      }
      .swiper-pagination-bullet-active {
        background-color: $primary;
      }
    }
  }
