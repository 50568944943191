#branding {
    .font-sample {
        font-size: 1.5rem;
        overflow-wrap: break-word;
    }
    .sample {
        background: #f7f7f7;
        height: 10vh;
    }
}
