///
// Fonts
///

// Miso
@font-face {
    font-family: "Miso";
    src: url("#{$baseurl}/assets/fonts/miso-regular.woff") format("woff");
  }

.ft-miso {
  font-family: "Miso";
}
