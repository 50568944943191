#timeline {
    $timeline-gap: 30px;
    $timeline-width: 2px;

    .text-end::after {
        content: '';
        width: $timeline-gap;
        height: 100%;
        position: absolute;
        border-right: $timeline-width solid $primary;
        top: 0;
    }

    .text-start {
        padding-left: $timeline-gap * 1.5;
    }

    .text-start::before {
        content: '';
        width: $timeline-gap;
        height: 100%;
        position: absolute;
        border-left: $timeline-width solid $primary;
        top: 0;
        left: ($timeline-gap + $timeline-width) / 2;
    }
}

#contributors {

.contributors .contributors-item {
  box-sizing: content-box;
  padding: 0px 0px 0 0px;
  margin: 0px 0px;
  text-align: left;
  min-height: 0px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.0);
}

.contributors .contributors-item .contributors-img {
  width: 55px;
  border-radius: 50%;
  margin: 5px 5px 5px 5px;
}
}

#maintainers {
.maintainers-img {
  width: 22px;
  border-radius: 50%;
  margin: 1px;
}
}
