#blog-header {
  background: $dark;
  padding: 80px 0;
  height: 480px;
  overflow: hidden;
  position: relative;
  .background {
    position:absolute;
    top:0;
    z-index:1;
    width:100%;
    height:100%;
    opacity:0.5;
    img {
      object-fit:cover;
      left: 0;
      right: 0;
      z-index: 1;
      display: block;
      width: 100%;
      height: 100%;
      -webkit-filter: blur(5px) opacity(0.4);
      -moz-filter: blur(5px) opacity(0.4);
      -o-filter: blur(5px) opacity(0.4);
      -ms-filter: blur(5px) opacity(0.4);
      filter: blur(5px) opacity(0.4);
    }
  }
  .container *{
    position: relative;
    z-index: 2;
  }
}
