#drivers {
    .btn-primary:hover {
        box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
    }
    .accordion-button {
        color: $light;
        &::after {
            background: none;
        }
        &:focus {
            border-color: $primary-light;
            box-shadow: 0 0 0 0.25rem rgba($primary, .25);
            outline: 0;
            z-index: 3;
        }
        &:not(.collapsed) {
            background-color: $primary-light;
        }
        h5 {
            position: relative;
            & i {
                position:absolute; // makes to position to right corner of header
                right:0px;
                float:right;
            }
        }
    }
    .accordion-flush .accordion-item:first-of-type .accordion-button {
        border-radius: .25rem !important;
    }
}
