// RIOT colors
$riot-red: #bc202a;
$riot-green: #40a687;

// Abstraction of colors
$primary:$riot-green;
$primary-light: lighten($primary, $amount: 20);

$secondary:$riot-red;
$secondary-light: lighten($color: $secondary, $amount: 20);

$light: #ffffff;
$dark: #212121;
$gray: #aaaaaa;
