// General styles

body {
  font-family: "Open Sans", sans-serif;
  color: #444444;
}

a {
  color: $primary;
  text-decoration: none;

  &:hover {
  color: $primary-light;
  text-decoration: none;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Raleway";
}

.check_style li {
  padding-left: 20px;
}

.check_style li:before {
  content: "\eb7a";
  font-family: remixicon;
  display: inline-block;
  color: $primary;
  margin-left: -1.3em; /* same as padding-left set on li */
}

.background-dark {
  background-color: $dark !important;
}

.btn-primary {
  color: #fff;
  background-color: $primary;
  border-color: $primary;
  &:hover {
    color: #fff;
    background-color: $primary-light;
    border-color: $primary-light;
  }
  &:hover, &:active, &:focus {
    color: #fff;
    background-color: $primary-light;
    border-color: $primary-light;
    box-shadow: none !important;
  }
}

.btn-outline-primary {
  color: $primary;
  border-color: $primary;

  &:hover, &:active {
    color: $light;
    border-color: $primary;
    background-color: $primary;
  }
  &:focus, &:active:focus {
    box-shadow: 0 0 0 0.25rem rgba($primary, .5);
  }
}

  .btn-learn-more {
    font-family: "Raleway", sans-serif;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 50px;
    transition: 0.5s;
    line-height: 1;
    margin: 10px;
    color: $light;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    background: $primary;
    border: 2px solid $primary;
  }

  .btn-learn-more:hover {
    background: $light;
    color: $primary;
    text-decoration: none;
  }

.text-gray {
  color: $gray !important;
}
