#header {
  height: 90px;
  z-index: 997;
  transition: all 0.5s;
  background: transparentize($dark, 0.05);

  .logo img {
    margin: 0;
    max-height: 60px;
    transition: all 0.5s;
    padding: 0;
  }

  &.header-transparent {
    background: transparent;
  }
  
  &.header-scrolled {
    background: transparentize($dark, 0.05);
    height: 70px;

    .logo img {
      padding: 0;
      margin: 0;
      max-height: 40px;
    }
  }
}
